<template>
  <div class="navigation">
    <section v-if="services && services.length > 0">
      <h1>Сервисы</h1>
      <nav class="services">
        <div v-for="method in services" :key="method.type" class="btn w-auto"  @click.exact="clickListItem(method)">
          <div>{{ method.name }}</div>
          <span :class="{ active: true }">•</span>
        </div>
      </nav>
    </section>
    <div v-if="services === false">
      <h1>Сервер Offline</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  data() {
    return {
      services: [
        { name: 'Ubuntu 18', type: 'ubuntu_18' },
        { name: 'Ubuntu 20', type: 'ubuntu_20' },
        { name: 'NodeJS Setup', type: 'node_js' },
        { name: 'MongoDB', type: 'mongo_db' },
        { name: 'PostgreSQL', type: 'postgre_sql' },
        { name: 'NGINX Manager', type: 'nginx' },
        { name: 'GitHub Actions', type: 'deployment' },
        { name: 'Bash Commands', type: 'bash' },
      ]
    }
  },
  methods: {
    clickListItem(method) {
      this.$router.push('/services/'+method.type)
    }
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/components/nav'
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  {
    path: '/services', name: 'Services', component: () => import('@/views/Pages/Services'), children: [
      { path: 'deployment', name: 'Deployment', component: () => import('@/views/Services/Deployment') },
      { path: 'nginx', name: 'Nginx', component: () => import('@/views/Services/Nginx') },
      { path: 'ubuntu_18', name: 'Ubuntu 18', component: () => import('@/views/Services/Ubuntu18') },
      { path: 'ubuntu_20', name: 'Ubuntu 20', component: () => import('@/views/Services/Ubuntu20') },
      { path: 'bash', name: 'Bash', component: () => import('@/views/Services/BashCommands') },
      { path: 'mongo_db', name: 'MongoDB', component: () => import('@/views/Services/MongoDB') },
      { path: 'postgre_sql', name: 'PostgreSQL', component: () => import('@/views/Services/PostgreSQL') },
      { path: 'node_js', name: 'NodeJS', component: () => import('@/views/Services/NodeJS') },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
<template>
  <header class="header">
    <nav>
      <div @click="$router.push('/')" class="leftHeader">
        <div class="top_home_logo"></div>
        <h1>System {{ getPage }}</h1>
      </div>
      <div class="rightHeader">
        <h3>Артём</h3>
        <img src="https://sun9-23.userapi.com/impf/tWtpUEThqxrctmYksqg-5jER4P5wwnaZyUI9DA/ZW_0YtIPutM.jpg?size=100x0&quality=88&crop=168,357,721,721&sign=994f7eb9a803ef10357650209bed0d98&ava=1" alt="avatar "/>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    getPage() {
      let prefix = ''
      const p = this.$route.path
      if (p.includes('/services/')) prefix = '/ Services'
      if (p.includes('/modules/')) prefix = '/ Modules'
      return prefix + ' / ' + this.$route.name
    }
  }
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/components/header'
</style>
const app = {
  namespaced: true,
  state: {
    server_online: false,
  },
  mutations: {
    set_server_online(state, payload) {
      state.server_online = payload
    },
  },
  getters: {
    get_server_online: (state) => state.server_online,
    get_local_mode: () => process.env.NODE_ENV === 'development',
    get_api_link: () => process.env.NODE_ENV === 'development' ? 'http://localhost:7000' : 'https://server.yablonev.art',
  },
}

export default app
import Vue from 'vue'
import App from '@/App'
import router from '@/router.js'
import store from '@/store.js'
import VueHighlightJS from 'vue-highlightjs'

Vue.use(VueHighlightJS)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
<template>
  <div class="home">
    <Nav />
  </div>
</template>

<script>
import Nav from '@/components/Nav'

export default {
  name: 'Home',
  components: { Nav }
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/pages/home'
</style>
<template>
  <div id="app">
    <Header />
    <div class="bg"></div>
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import { API } from '@/logic/helper'
import Header from '@/components/Header'

export default {
  name: 'App',
  components: { Header },
  computed: {
    server_online() {
      return this.$store.getters['app/get_server_online']
    },
    get_title() {
      return this.$route.name
    }
  },
  methods: {
    changeTitle() {
      let t = 'Yablonev System | '
      t += this.server_online ? this.get_title : 'Offline'
      document.title = t
    }
  },
  async mounted() {
    const result = await API()
    this.$store.commit('app/set_server_online', result.status === 200)
  },
  watch: {
    server_online() {
      this.changeTitle()
    },
    get_title() {
      this.changeTitle()
    }
  }
}
</script>

<style lang="sass">
@import '@/assets/sass/code.sass'
@import '@/assets/sass/app'
</style>